import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import {
  withStyles,
  Button,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  TableFooter,
  TablePagination,
  WithStyles,
  createStyles,
  Typography
} from '@material-ui/core';
import Header from '../Header/Header';
import AddIcon from '@material-ui/icons/Add';
import { tgreen100, tgreen20 , tgrey100, tgreyM, tgreyBD, tgreyBG, tgreyR } from '../../styles/colors';
import { AppState, License, LicenseStatus } from '../../Models/AppState';
import EnhancedTable, { EnhancedTableContext, EnhancedTableRow, tableFooterLabel } from '../Common/EnhancedTable';
import { formatDate } from '../../actions/utils';
import { RouteComponentProps } from 'react-router-dom';
import { getAllLicenses } from '../../actions/server';
import textConstants from '../../actions/constants';

const styles = createStyles({
  mainWrapper: {
    margin: '1em',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '.5em',
  },
  title: {
    color: tgreyM,
    fontSize: '20px',
    fontWeight: 600
  },
  addIcon: {
    marginRight: '10px'
  },
  root: {
    boxShadow: 'none',
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    border: `1px solid ${tgreyBD}`,
    borderTop: 'none',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      background: tgreen20
    }
  },
  tableRowSearch: {
    background: tgreyR
  },
  formInput: {
    width: '160px',
    height: '36px',
    boxSizing: 'border-box',
    padding: '0px 16px',
    border: `1px solid ${tgreyBD}`,
    borderRadius: '2px',
    backgroundColor: tgreyBG,
    fontSize: '13px',
    transition: 'all 0.5s ease-out',
    '&:focus': {
      border: `2px solid ${tgreen100}`,
      boxShadow: '0 0 0 0.05rem rgba(0, 0, 0,.16)',
    },
  },
  formSelect: {
    width: '140px',
    height: '33px',
    paddingTop: '3px',
    border: `1px solid ${tgreyBD}`,
    fontSize: '13px',
    backgroundColor: tgreyBG,
    color: tgrey100,
    '&:hover': {
      border: `2px solid ${tgreen100}`,
      boxShadow: '0 0 0 0.05rem rgba(0, 0, 0,.16)',
    }
  },
  formMenuItem: {
    height: '10px',
    fontSize: '13px',
    color: tgrey100
  },
  rowPagination: {
    backgroundColor: tgreyR
  }
});

interface ListProps extends WithStyles<typeof styles> { }

type AllProps = ListProps & DispatchProp & RouteComponentProps & MSTP;

interface State {
  page: number;
}

const rows: EnhancedTableRow[] = [
  {id: 'uuid', label: 'Лицензия', filter: 'text'},
  {id: 'companyName', label: 'Клиент', filter: 'text'},
  {id: 'status', label: 'Статус Лицензии', getValue: (license: License) => textConstants['RUS'][license.status]},
  {id: 'activationsCount', label: 'Количество Активаций'},
  {id: 'camAmount', label: 'Количество Камер'},
  {id: 'detAmount', label: 'Количество Детекций'},
  {
    id: 'expirationDate',
    label: 'Активна до',
    renderCell: l => (
      <TableCell key={l.id}>
        <span style={{color: l.status === LicenseStatus.ACTIVATED && new Date().getTime() > new Date(l.expirationDate).getTime() ? 'red' : ''}}>
          {formatDate(l.expirationDate)}
        </span>
      </TableCell>
    )
  },
  {id: 'createDate', label: 'Дата создания', getValue: license => formatDate(license.createDate)},
];

const rowsPerPage = 15;

class List extends React.Component<AllProps, State> {
  state: State = {
    page: 0,
  }

  componentDidMount() {
    const {dispatch, history} = this.props;
    getAllLicenses(dispatch, history);
  }

  handleClickRow = (ls: License) => {
    this.props.history.push(`/license/${ls.id}`);
  }

  handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    this.setState({ page });
  };

  render() {
    const { classes, data } = this.props;
    const { page } = this.state;
    return (
      <div>
        <Header />
        <section className={classes.mainWrapper}>
          <div className={classes.titleWrapper}>
            <Typography variant="h5">Список лицензий</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.props.history.push('add_license')}
            >
              <AddIcon className={classes.addIcon} />
              Добавить лицензию
            </Button>
          </div>
          
          <Paper className={classes.root}>
            <EnhancedTable
              data={data}
              rows={rows}
              defaultSort={{id: 'createDate', order: 'desc'}}
              onRowClick={row => this.props.history.push(`/license/${row.id}`)}
            />
            {/* <EnhancedTable>
              <EnhancedTableContext.Consumer>

              </EnhancedTableContext.Consumer>
            </EnhancedTable> */}
            {/* <EnhancedTable data={data} rows={rows} defaultSort={{id: 'createDate', order: 'desc'}}>
              <EnhancedTableContext.Consumer>
                {(value: License[]) => (
                  <>
                  <TableBody>
                    {value
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(l => (
                        <TableRow
                          hover
                          key={l.id}
                          onClick={() => this.handleClickRow(l)}
                        >

                          <TableCell style={{ width: 100 / rows.length + '%' }} >
                            {l.uuid}
                          </TableCell>

                          <TableCell style={{ width: 100 / rows.length + '%' }} >
                            {l.companyName}
                          </TableCell>

                          <TableCell style={{ width: 100 / rows.length + '%' }} >
                            {textConstants['RUS'][l.status]}
                          </TableCell>

                          <TableCell style={{ width: 100 / rows.length + '%' }} >
                            {l.activationsCount}
                          </TableCell>

                          <TableCell style={{ width: 100 / rows.length + '%' }} >
                            {l.camAmount}
                          </TableCell>

                          <TableCell style={{ width: 100 / rows.length + '%' }} >
                            <span style={{color: l.status === LicenseStatus.ACTIVATED && new Date().getTime() > new Date(l.expirationDate).getTime() ? 'red' : ''}}>
                              {formatDate(l.expirationDate)}
                            </span>
                          </TableCell>

                          <TableCell style={{ width: 100 / rows.length + '%' }} >
                            {formatDate(l.createDate)}
                          </TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[]}
                        count={value.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={this.handleChangePage}
                        labelDisplayedRows={tableFooterLabel}
                      />
                    </TableRow>
                  </TableFooter>
                  </>
                )}
              </EnhancedTableContext.Consumer>
            </EnhancedTable> */}
          </Paper>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    data: state.mainReducer.licenses,
  };
};

type MSTP = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(withStyles(styles)(List));