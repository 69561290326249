import * as React from 'react';
import { FormControl, TextField, FormHelperText, WithStyles, createStyles, withStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';
import { tred60, tgreyBD, tgreyBG, tgreen100, tgreySUB } from '../../styles/colors';
import { disableSymbols, positiveNumber, requiredStr, requiredNumber } from '../../validation';
import { reduxForm, Field, InjectedFormProps, WrappedFieldProps } from 'redux-form';
import { User, License } from '../../Models/AppState';
import { renderTextField } from '../Common/FormCommon';
import { DatePicker } from 'material-ui-pickers';

const styles = createStyles({
  formWrapper: {
    padding: '0 25px'
  },
  formList: {
    padding: 0
  },
  formListItem: {
    display: 'flex',
    'align-items': 'center',
    marginTop: '5px',
    '&:first-child': {
      marginTop: 0
    }
  },
  checkboxFieldsSwitchContainer: {
    paddingLeft: '1rem'
  },
  formSubTitle: {
    width: '170px',
    fontSize: '13px',
    color: tgreySUB
  },
  formField: {
    fontSize: '13px'
  },
  formInput: {
    width: '400px',
    height: '30px',
    marginTop: '20px',
    boxSizing: 'border-box',
    padding: '0px 16px',
    border: `1px solid ${tgreyBD}`,
    borderRadius: '2px',
    backgroundColor: tgreyBG,
    fontSize: '13px',
    transition: 'all 0.5s ease-out',
    '&:focus': {
      border: `2px solid ${tgreen100}`,
      boxShadow: '0 0 0 0.05rem rgba(0, 0, 0,.16)',
    },
  },
  formInputError: {
    border: `2px solid ${tred60}`,
    boxShadow: '0 0 0 0.05rem rgba(0, 0, 0,.16)',
  },
  rootCheckBox: {
    marginLeft: '-15px',
  }
});

enum numberInputs {
  cam = 'camAmount',
  det = 'detAmount',
}

const numberInputsData = [
  {
    id: 'cameraNum',
    name: numberInputs.cam,
    placeholder: 'Кол-во камер',
  },
  {
    id: 'detectionNum',
    name: numberInputs.det,
    placeholder: 'Кол-во детекций',
  }
]

const renderNumberInput = (props: any) => {
  const { style, input, meta: { error, touched, active }, disabled, inputData } = props;
  return (
    <FormControl error={!!error && (touched || active)}>
      <TextField
        disabled={disabled}
        placeholder={inputData.placeholder}
        id={inputData.id}
        type="number"
        autoComplete="off"
        onKeyPress={(event) => disableSymbols(event)}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: error && (touched || active) ? `${style.formInput} ${style.formInputError}` : style.formInput,
          },
        }}
        {...input}
      />
      {!!error &&
      <FormHelperText id="name-error-text">{error}</FormHelperText>
      }
      {!error &&
      <FormHelperText
          id="name-error-text"
      />
      }
    </FormControl>
  );
};

const renderDataPicker = (props: WrappedFieldProps) => {
  const tomorrow = new Date();
  return(
    <DatePicker
      margin="normal"
      disablePast
      value={props.input.value}
      onChange={props.input.onChange}
      format="dd/MMM/yyyy"
      minDate={tomorrow.setDate(tomorrow.getDate() + 1)}
    />
  );
}

interface AdditionalProps {
  user: User | null;
}

interface StyledProps extends WithStyles<typeof styles> {}

class NewLicenseFormCmp extends React.Component<
  AdditionalProps & StyledProps & InjectedFormProps<License, AdditionalProps
  >> {

  state = {
    [numberInputs.cam]: {
      disabled: false,
    },
    [numberInputs.det]: {
      disabled: true,
    }
  }

  onSwitchFields: SwitchBaseProps['onChange'] = (_, value) => {
    this.setState((state) => {
      const newState = {}
      Object.keys(numberInputs).forEach((key) => {
        // @ts-ignore
        newState[numberInputs[key]] = { disabled: !state[numberInputs[key]].disabled }
      })
      return newState
    }, () => {
      Object.keys(numberInputs).forEach((key) => {
        // @ts-ignore
        if (this.state[numberInputs[key]].disabled) this.props.change(numberInputs[key], '')
      })
    })
  }

  switchFieldsValidation = (disabled: boolean) => {
    return disabled ? [ positiveNumber ] : [ positiveNumber, requiredNumber ]
  }

  parseNumberValue(value: number | string) {
    if (value === '') return value
    else return Number(value)
  }

  render() {
    const { classes, handleSubmit, user } = this.props;
    return (
      <form id="formNewLicense" onSubmit={handleSubmit} autoComplete="off">
        <ul className={classes.formList}>

          {
            numberInputsData.map((data, key) => <li className={classes.formListItem} key={key}>
              <p className={classes.formSubTitle}>{data.placeholder}</p>
              <div className={classes.formField}>
                <Field
                  name={data.name}
                  component={renderNumberInput}
                  style={classes}
                  validate={this.switchFieldsValidation(this.state[data.name].disabled)}
                  parse={this.parseNumberValue}
                  // @ts-ignore
                  props={{
                    disabled: this.state[data.name].disabled,
                    inputData: data
                  }}
                />
              </div>
              {data.name === numberInputs.cam && <FormControlLabel
                classes={{ label: classes.formSubTitle, root: classes.checkboxFieldsSwitchContainer }}
                label={'Камеры'}
                control={<Checkbox defaultChecked={true} onChange={this.onSwitchFields} />}
              />}
            </li>)
          }

          <li className={classes.formListItem}>
            <p className={classes.formSubTitle}>Активна до</p>
            <div className={classes.formField}>
              <Field
                name="expirationDate"
                component={renderDataPicker}
              />
            </div>
          </li>

          <li className={classes.formListItem}>
            <p className={classes.formSubTitle}>Клиент</p>
            <div className={classes.formField}>
              <Field
                name="companyName"
                type="text"
                component={renderTextField}
                style={classes}
                validate={[requiredStr]}
              />
            </div>
          </li>

          <li className={classes.formListItem}>
            <p className={classes.formSubTitle}>Парт номер</p>
            <div className={classes.formField}>
              <Field
                name="partNumber"
                type="text"
                component={renderTextField}
                style={classes}
                validate={[requiredStr]}
              />
            </div>
          </li>

          <li className={classes.formListItem}>
            <p className={classes.formSubTitle}>Создал</p>
            <div className={classes.formField}>{user ? user.email : ''}</div>
          </li>
        </ul>
      </form>
    );
  }
}

export default reduxForm<License, AdditionalProps>({form: 'newLicense'})(withStyles(styles)(NewLicenseFormCmp));
